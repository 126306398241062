import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useAuth } from "../../../../../app/modules/auth";
import { useLocation } from "react-router-dom";
import { MenuInnerWithPrimaryMenu } from "./MenuInnerWithPrimaryMenu";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import { MegaDash } from "./MegaDash";
import { MegaAudioDash } from "./MegaAudioDash";
import { MegaManage } from "./MegaManage";
import { MegaDashAnalytics } from "./MegaDashAnalytics";

export function MenuInner() {
  const intl = useIntl();

  const location = useLocation();

  const { currentUser } = useAuth();

  const customerType = sessionStorage.getItem("CUSTOMER_CONTENT_TYPE");

  // console.log("CustomerType::", customerType)

  const {
    permissionVideos, permissionSeries, permissionLiveTV, permissionLibrary, permissionEncoding, permissionVideoLayout,
    permissionAlbums, permissionPodcasts, permissionPlayist, permissionAudioGenres, permissionAudioLayout,
    permissionCategories, permissionLanguage, permissionGenres, permissionProdHouse, permissionCasts, permissionGeoRestriction, permissionContentPartner, 
    permissionAdminUsers, permissionRoles, permissionBroadcastGroups, permissionBroadcastMessages, permissionFeedbacks,
    permissionContentAnalystics, permissionCustomerAnalystics, 
    permissionAudioAnalystics,permissionAudioAlbumAnalystics, permissionAudioTrackAnalystics, permissionAudioPlaylistAnalystics,
    permissionDeviceAnalystics, permissionGeoLocationAnalystics, permissionRealTimeAnalystics, 
    permissionRevenueAnalystics, permissionContentPartnerAnalystics, permissionMonitorAnalystics,
    permissionPlans, permissionTransaction, permissionAds, permissionSubscription, 
    permissionRental, permissionPaymentGateway, permissionCoupon,
    permissionStorageSettings, permissionDashboard, permissionGeoFencing, 
    permissionUserManagement, permissionSocialLinks, permissionImageConfig
    } = usePermissionValues();

  const permission_Content_Settings = permissionCategories.isViewable || permissionLanguage.isViewable || permissionGenres.isViewable ||
                                      permissionProdHouse.isViewable || permissionCasts.isViewable || permissionGeoRestriction.isViewable;

  const permission_Admin = permissionAdminUsers.isViewable || permissionRoles.isViewable;

  const permission_Broadcast = permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable;

  const permission_Accounts =  permissionUserManagement.isViewable || permissionAdminUsers.isViewable || 
  permissionContentPartner.isViewable || permissionRoles.isViewable;

  const permission_App_Settings = permissionSocialLinks.isViewable || permissionImageConfig.isViewable || permissionGeoFencing.isViewable;

  const Permission_Analytics =  permissionContentAnalystics.isViewable || permissionCustomerAnalystics.isViewable || 
                                permissionAudioAlbumAnalystics.isViewable ||
                                permissionAudioTrackAnalystics.isViewable || permissionAudioPlaylistAnalystics.isViewable ||
                                permissionDeviceAnalystics.isViewable || permissionGeoLocationAnalystics.isViewable || 
                                permissionRealTimeAnalystics.isViewable || permissionRevenueAnalystics.isViewable || 
                                permissionContentPartnerAnalystics.isViewable || permissionMonitorAnalystics.isViewable

  // console.log("contentSettings permission:", permission_Admin);

  return (
    <>
      {(location.pathname === "/dashboard" ||
        location.pathname !== "/customers/list") && (
        <>
          {permissionDashboard.isViewable && (
            <MenuItem
              title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
              to="/dashboard"
            />
          )}
          {(permissionVideos.isViewable ||
            permissionSeries.isViewable ||
            permissionLiveTV.isViewable ||
            permissionLibrary.isViewable ||
            permissionVideoLayout.isViewable ||
            permissionEncoding.isViewable) && (customerType === "VOD") && (
            <MenuInnerWithSub
              title="Media"
              to="/content"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
              // isAnalytics={Permission_Analytics_Video}
              isAnalytics={false}
            >
              <MegaDash 
              title="Video"
              to="/content"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              />
            </MenuInnerWithSub>
          )}
          {(permissionAlbums.isViewable ||
            permissionPodcasts.isViewable ||
            permissionPlayist.isViewable ||
            permissionAudioGenres.isViewable ||
            permissionAudioLayout.isViewable) && (customerType === "AOD") && (
            <MenuInnerWithSub
              title="Audio"
              to="/audio"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
              // isAnalytics={Permission_Analytics_Audio}
              isAnalytics={false}
            >
              <MegaAudioDash />
            </MenuInnerWithSub>
          )}
          {(permission_Content_Settings) && (
            <MenuInnerWithSub
              title="Content Settings"
              to="/setting-contents"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
            {permissionGenres.isViewable &&
              <MenuItem
                title="Genre"
                to="/setting-contents/genre"
                fontIcon="bi bi-collection-play-fill"
              />}

            {permissionCasts.isViewable &&
              <MenuItem
                title="Cast"
                to="/setting-contents/cast"
                fontIcon="bi bi-people-fill"
              />}
            {permissionProdHouse.isViewable &&
              <MenuItem
              title="Production House"
              to="/setting-contents/production-company"
              fontIcon="bi bi-film"
              />}
            {permissionLanguage.isViewable &&
              <MenuItem
              title="Language"
              to="/setting-contents/language"
              fontIcon="bi bi-mic-fill"
              />}
            {permissionCategories.isViewable &&
              <MenuItem
              title="Category"
              to="setting-contents/category"
              fontIcon="bi bi-database-fill"
              />}
            {permissionGeoRestriction.isViewable &&
            <MenuItem
                title="Geo Profiles"
                to="/setting-contents/geo-profiles"
                fontIcon="bi bi-globe-americas"
              />
              }
            </MenuInnerWithSub>
          )}          
          {(Permission_Analytics) && (
            <MenuInnerWithSub
              title="Analytics"
              to="/analytics"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
            >
              <MegaDashAnalytics 
              title="Analytics"
              to="/content"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              />              
            {/* {permissionContentAnalystics.isViewable && (customerType === "VOD") &&
              <MenuItem
                title="Content Analytics"
                to="/analytics/analytics-content"
                fontIcon="bi bi-bar-chart-fill"
              />}

            {permissionAudioAlbumAnalystics.isViewable && (customerType === "AOD") &&
              <MenuItem
                title="Album Analytics"
                to="/analytics/analytics-audio-album"
                fontIcon="bi bi-music-note-list"
              />}
            {permissionAudioTrackAnalystics.isViewable && (customerType === "AOD") &&
              <MenuItem
                title="Track Analytics"
                to="/analytics/analytics-audio-track"
                fontIcon="bi bi-file-music-fill"
              />}
            {permissionAudioPlaylistAnalystics.isViewable && (customerType === "AOD") &&
              <MenuItem
                title="Playlist Analytics"
                to="/analytics/analytics-audio-playlist"
                fontIcon="bi bi-file-earmark-play-fill"
              />}
            {permissionCustomerAnalystics.isViewable &&
              <MenuItem
                title="Customer Analytics"
                to="/analytics/audience"
                fontIcon="bi bi-person-lines-fill"
              />}
            {permissionContentPartnerAnalystics.isViewable &&
            <MenuItem
                title="Content Partner"
                to="/analytics/partner"
                fontIcon="bi bi-fast-forward-btn-fill"
              />
              }
            {permissionDeviceAnalystics.isViewable && (customerType === "VOD") &&
              <MenuItem
                title="Devices"
                to="/analytics/analytics-devices"
                fontIcon="bi bi-pc-display"
              />
            }
           {permissionGeoLocationAnalystics.isViewable &&
              <MenuItem
                title="Geo Location"
                to="/analytics/analytics-geolocation"
                fontIcon="bi bi-pin-map-fill"
              />
            }
           {permissionRealTimeAnalystics.isViewable &&
              <MenuItem
                title="Real time"
                to="/analytics/analytics-realtime"
                fontIcon="bi bi-stopwatch-fill"
              />
            }
            {permissionRevenueAnalystics.isViewable &&
              <MenuItem
                title="Revenue"
                to="/analytics/analytics-revenue"
                fontIcon="bi bi-cash stack"
              />
            }
            {permissionMonitorAnalystics.isViewable &&
              <MenuItem
                title="Grafana Dashboard"
                to="/analytics/analytics-monitor"
                fontIcon="bi bi-pie-chart-fill"
              />
            } */}
            </MenuInnerWithSub>
          )}
          {(permissionPlans.isViewable || permissionTransaction.isViewable || permissionAds.isViewable ||
          permissionSubscription.isViewable || permissionRental.isViewable ||
           permissionPaymentGateway.isViewable || permissionCoupon.isViewable) && (
            <MenuInnerWithSub
              title="Monetization"
              to="/monetization"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
            {permissionPlans.isViewable &&
              <MenuItem
                title="Plans"
                to="/monetization/plans"
                fontIcon="bi bi-card-list"
              />}
            {permissionTransaction.isViewable &&
              <MenuItem
                title="Transactions"
                to="/monetization/transaction"
                fontIcon="bi bi-credit-card"
              />}
            {permissionAds.isViewable &&
              <MenuItem
                title="Manage Ads"
                to="/monetization/ads"
                fontIcon="bi bi-badge-ad"
              />}              
            {permissionSubscription.isViewable &&
              <MenuItem
                title="Subscription"
                to="/monetization/subscription"
                fontIcon="bi bi-cash-stack"
              />}
            {permissionRental.isViewable &&
              <MenuItem
                title="Rental"
                to="/monetization/rental"
                fontIcon="bi bi-calendar3"
              />}
            {permissionPaymentGateway.isViewable &&
              <MenuItem
                title="Payment Gateways"
                to="/monetization/payment-gateways"
                fontIcon="bi bi-currency-dollar"
              />}
           {permissionCoupon.isViewable &&
              <MenuItem
                title="Coupons"
                to="/monetization/coupons"
                fontIcon="bi bi-ticket-detailed"
              />}
            </MenuInnerWithSub>
          )}
          {(permission_Accounts ||
            permission_App_Settings ||
            permission_Broadcast ||
            permissionStorageSettings.isViewable || 
            permissionFeedbacks.isViewable) && (
            <MenuInnerWithSub
              title="Manage"
              to="/manage"
              menuPlacement="bottom-end"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
              isAnalytics= {true}
            >
              <MegaManage 
              title="Manage"
              to="/manage"
              menuPlacement="bottom-end"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              />
            </MenuInnerWithSub>   )}   

          {/* {(permission_Content_Settings ||
            permission_Admin ||
            permission_Broadcast ||
            permissionStorageSettings.isViewable || 
            permissionUserManagement.isViewable ||
            permissionFeedbacks.isViewable ||
            permissionGeoFencing.isViewable) && (
            <MenuInnerWithSub
              title="Manage"
              to="/manage"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
              {permission_Admin && 
              <MenuInnerWithSub
                title="Accounts"
                to="/manage/usermanagement"
                fontIcon="bi-person-fill-gear"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
            {permissionUserManagement.isViewable &&
                <MenuItem
                to="/manage/accounts/users"
                  title="Customers"
                  hasBullet={true}
                />}                
            {permissionAdminUsers.isViewable &&
                <MenuItem
                  to="/manage/accounts/admins"
                  title="Admins"
                  hasBullet={true}
                />}
            {permissionGeoRestriction.isViewable &&
                <MenuItem
                to="/manage/accounts/cont-partner"
                title="Content Partner"
                hasBullet={true}
                />}    
            {permissionRoles.isViewable &&
                <MenuItem
                  to="/manage/accounts/roles"
                  title="Roles"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>}

              {(currentUser?.userType === "SUPERADMIN") &&
              <MenuInnerWithSub
                title="Integrations"
                to="/settings"
                fontIcon="bi-gear-wide-connected"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                <MenuItem
                  to="/settings/email-config"
                  title="Email Config"
                  hasBullet={true}
                />                
                <MenuItem
                  to="/settings/tmdb"
                  title="TMDB"
                  hasBullet={true}
                />
                <MenuItem
                  to="/settings/firebase"
                  title="Firebase"
                  hasBullet={true}
                />
                <MenuItem
                to="/manage/integration/storage"
                title="Storage"
                hasBullet={true}
                />
              </MenuInnerWithSub>}

             {(permissionSocialLinks.isViewable || permissionImageConfig.isViewable) && 
              <MenuInnerWithSub
                title="App Settings"
                to="/page-settings"
                fontIcon="bi-gear-fill"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
              {(permissionSocialLinks.isViewable) && 
                <MenuItem
                  to="/page-settings/social-links"
                  title="Social Links"
                  hasBullet={true}
                />}
              {(permissionImageConfig.isViewable) && 
                <MenuItem
                  to="/page-settings/image-config/login"
                  title="Image Config"
                  hasBullet={true}
                />}
              {permissionGeoFencing.isViewable &&
                <MenuItem
                to="/manage/geofencing"
                title="Geo Fencing"
                  hasBullet={true}
                />}                
              </MenuInnerWithSub>}              

              {permission_Broadcast && 
              <MenuInnerWithSub
                title="Notification"
                to="/manage/notification"
                fontIcon="bi-layers"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
            {permissionBroadcastGroups.isViewable &&
                <MenuItem
                  to="/manage/notification/groups"
                  title="Groups"
                  hasBullet={true}
                />}
            {permissionBroadcastMessages.isViewable &&
                <MenuItem
                  to="/manage/notification/broadcast"
                  title="Messages"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>}

              {permissionFeedbacks.isViewable && 
              <MenuInnerWithSub
                title="Help & Support"
                to="/manage/notification"
                fontIcon="bi-layers"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
              {permissionFeedbacks.isViewable &&
                <MenuItem
                to="/manage/accounts/feedbacks"
                  title="Feedbacks"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>} */}



                         {/* {permissionFeedbacks.isViewable &&
              <MenuItem
                title="Feedbacks"
                to="/manage/accounts/feedbacks"
                fontIcon="bi-ticket"
              />}  */}

 



              {/* STORAGE SETTINGS */}
              {/* {permissionStorageSettings.isViewable &&
              <MenuItem
                title="Storage Settings"
                to="/manage/integration/storage"
                fontIcon="bi-folder"
              />
              } */}

              {/* USER MANAGEMENT */}
              {/* {permissionUserManagement.isViewable &&
              <MenuItem
                title="Customers"
                to="/manage/accounts/users"
                fontIcon="bi-person"
              />} */}
              {/* Feedback */}

              {/* GEO Fencing */}


            {/* </MenuInnerWithSub>
          )} */}

          {/* {(currentUser?.userType === "SUPERADMIN" ||
            permissionGeoFencing.isViewable) && (
            <MenuInnerWithSub
              title="Settings"
              to="/settings"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
          
              <MenuItem
                title="Integration"
                to="/settings/firebase"
                fontIcon="bi bi-intersect"
              />
             
            </MenuInnerWithSub>
          )} */}

          
        </>
      )}
      {/* {(permissionVideos.isViewable || permissionSeries.isViewable || permissionLiveTV.isViewable ||
            permissionLibrary || permissionVideoLayout || permissionEncoding.isViewable) && ( 
          <MenuItem
            title={intl.formatMessage({ id: "MENU.VIDEOS" })}
            to="/content"
          />)} */}
      {/* {(permissionAlbums.isViewable || permissionPodcasts.isViewable || 
            permissionPlayist.isViewable || permissionAudioGenres.isViewable || permissionAudioLayout.isViewable) && (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.AUDIOS" })}
            to="/audio"
          />)} */}
      {/* {(permissionCustomerAnalystics.isViewable || permissionContentAnalystics.isViewable || permissionAudioAnalystics.isViewable) && (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.ANALYTICS" })}
            to="/analytics"
          />)} */}
      {/* {(permissionPlans.isViewable || permissionTransaction.isViewable) && (
           <MenuItem
            title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
            to="/monetization"
          />)} */}
      {/* {(permission_Content_Settings || permission_Admin || permission_Broadcast || 
            permissionUserManagement.isViewable || permissionFeedbacks.isViewable) &&  (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.MANAGE" })}
            to="/manage"
          />
          )} */}
      {/* {((currentUser?.userType === 'SUPERADMIN') || (permissionGeoFencing.isViewable)) && 
          <MenuItem
            title={'Settings'}
            to="/settings"
          />
          } */}
      {/* {(location.pathname === "/content" || 
        (location.pathname.includes("/content") )) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Content"
            to="/content"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionVideos.isViewable) && (
            <MenuItem to="/content/video" title="Video" /> )}
            {(permissionSeries.isViewable) && (
            <MenuItem to="/content/playlist" title="Series" /> )}
            {(permissionLiveTV.isViewable) && (
            <MenuItem to="/content/livestream" title="Live TV" />)}
            {(permissionLibrary.isViewable) && (
            <MenuItem to="/content/library" title="Library" />)}
            {(permissionEncoding.isViewable) && (
            <MenuItem to="/content/encoding" title="Encoding" />)}
            {(permissionVideoLayout.isViewable) && (
            <MenuItem to="/content/layout/general-layout" title="Layout" />)} 
            {/* <MenuItem
              title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
              to="content/monetization"
            /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/audio" ||
        location.pathname.includes("/audio")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Audio"
            to="/audio"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionAlbums.isViewable) && (
            <MenuItem to="/audio/albums" title="Albums" />)}
            {(permissionPodcasts.isViewable) && (
            <MenuItem to="/audio/podcast" title="Podcast" />)}
            {(permissionPlayist.isViewable) && (
            <MenuItem to="/audio/playlist" title="Playlist" />)}
            {(permissionAudioGenres.isViewable) && (
            <MenuItem to="/audio/genre" title="Genre" />)}
            {(permissionAudioLayout.isViewable) && (
            <MenuItem to="/audio/layout" title="Layout" /> )}
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/manage" ||
        location.pathname.includes("/manage")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Manage"
            to="/manage"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionCategories.isViewable || permissionLanguage.isViewable || permissionGenres.isViewable ||
            permissionProdHouse.isViewable || permissionCasts.isViewable || permissionGeoRestriction.isViewable ||
            permissionContentPartner.isViewable) &&  (
            <MenuItem to="/setting-contents" title="Content Settings" />)}
            {(permissionUserManagement.isViewable) && (
            <MenuItem to="/manage/accounts/users" title="User Management" />)}
            {(permissionAdminUsers.isViewable || permissionRoles.isViewable ) && (
            <MenuItem to="/manage/usermanagement" title="Admins" />)}
            {(permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable) && (
            <MenuItem to="/manage/notification" title="Broadcast" />)}
            {(permissionFeedbacks.isViewable) && (
            <MenuItem to="/manage/accounts/feedbacks" title="Feedback" />)}


            {/* <MenuItem to="/manage/production-company" title="Production House" />
            <MenuItem to="/manage/accounts/users" title="Customers" />
            <MenuItem to="/manage/usermanagement" title="Admins" />
            <MenuItem to="/manage/layout" title="Layout" /> 
            <MenuItem to="/manage/notification" title="Notification" /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/settings" ||
        location.pathname.includes("/settings")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Settings"
            to="/settings"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
           {currentUser?.userType === 'SUPERADMIN' && 
             <MenuItem to="/settings/firebase" title="Integration" />}
           {(permissionGeoFencing.isViewable) && (
             <MenuItem to="/manage/geofencing" title="Geo Fencing"/>)}
             {/* <MenuItem to="/settings/profile-settings/user" title="Profile Settings"/> */}
      {/* <MenuItem to="/settings/customerconfig" title="Customer Config" />
            <MenuItem to="/settings/payment" title="Payment" /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}

      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT" })}
        to="/analytics"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/analytics/user-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.VIDEOS" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.SERIES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.LAYOUTS" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_LIBRARY" })}
        to="/content-library"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-library/video-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.VIDEO_LIBRARY",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-library/audio-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.AUDIO_LIBRARY",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>  */}
      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS" })}
        to="/content-settings"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-settings/genre"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.GENRE" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/languages"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.LANGUAGES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/cast"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CAST" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/category"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CATEGORY" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
        to="/monetization"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/monetization/subscription"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.SUBSCRIPTION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/monetization/transactions"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.TRANSACTIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.NOTIFICATIONS" })}
        to="/notifications"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/notifications/app-version"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.APP_VERSION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notification-groups"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATION_GROUPS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notifications"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.SUPPORT" })}
        to="/support"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/support/tickets"
          title={intl.formatMessage({
            id: "MENU.SUPPORT.TICKETS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.ADMIN" })}
        to="/admin"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/admin/admin-users"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ADMIN_USERS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/admin/roles"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ROLES",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub> */}
    </>
  );
}
