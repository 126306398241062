import { useMemo } from "react";

export const RE_DIGIT = new RegExp(/^\d+$/);

export default function OtpInput({ value, valueLength, onChange, onOtpSumbit }) {
  const valueItems = useMemo(() => {
    const valueArray = value && value.split("");
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value;
    targetValue = targetValue.slice(-1);

    if (!RE_DIGIT.test(targetValue)) {
      targetValue = "";
    }
  
    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1);
  
    onChange(newValue);
  
    // Focus on the next input field if available
    if (targetValue !== "" && idx < valueLength - 1) {
      const nextElementSibling = target.nextElementSibling;
      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    }
  };
  

  const inputOnKeyDown = (e, value, onOtpSumbit) => {
    const target = e.target;
  
    if (e.key === "Enter") {
      e.preventDefault();
      if (value.length === 5) {
        onOtpSumbit();
        return;
      }
    }
  
    if (e.key === "Backspace" && target.value === "") {
      const previousElementSibling = target.previousElementSibling;
      if (previousElementSibling) {
        previousElementSibling.focus();
      }
    }
  };
  

  return (
    <div className="otp-group">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="otp-input"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={(e) => inputOnKeyDown(e,value,onOtpSumbit)}
          autoFocus = {idx === 0 ? true :false}
        />
      ))}
    </div>
  );
}
