import React from "react";
// import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
export default function Timer({ handleResendOTP, counter, setCounter, goToOTP }) {
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const secondsToTime = function (e, format = "m:s") {
    var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0");
    var m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");
    return format === "h:m"
      ? (h > 0 ? h + " hrs " : "") + m + " min"
      : m + ":" + s;
  };

  return (
    <div className="mt-2">
      {counter ? (
        <span className="text-gray-500 fw-semibold fs-6">
          The code is valid for {secondsToTime(counter)} seconds.
        </span>
      ) : (
        goToOTP()
        // <span className="inner-paratext">
        //   <div
        //     className=""
        //     onClick={(e) => {
        //       setCounter(10);
        //       handleResendOTP(e);
        //     }}
        //   >

        //       {/* <p className="text-primary fw-semibold fs-6"><span className="cursor-pointer"> <i className="bi bi-phone-fill text-primary"></i>  Resend OTP</span></p> */}
        //   </div>
        // </span>
      )}
    </div>
  );
}
