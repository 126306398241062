import { lazy, FC, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";

import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import {getUser} from '../modules/auth/core/AuthHelpers';
import { useAuth } from "../modules/auth";
import { usePermissionValues } from "../commonComponents/useCheckPermission";
import { useGetFirstPath } from "../commonComponents/useGetFirstPath";
import axios from "axios";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const defaultPath = useGetFirstPath();


  const ContentSettingsPage = lazy(
    () => import("../modules/videocontent")
  );
  const ManaeUsersPage = lazy(
    () => import("../modules/content-settings/index")
  );
  const LayoutPage = lazy(() => import("../modules/layout/index"));
  const AudioLayoutPage = lazy(() => import("../modules/audio-layout/index"));
  const NotificationPage = lazy(() => import("../modules/notification/index"));
  const ManageAccountsPage = lazy(() => import("../modules/adminroles/index"));
  const DashboardPage = lazy(() => import("../modules/dashboard/Page"));
  const DashboardAODPage = lazy(() => import("../modules/dashboard - AOD/Page"));
  const DashboardContPartnerVODPage = lazy(() => import("../modules/dashboard - VOD_ContentPartner/Page"));
  const DashboardContPartnerAODPage = lazy(() => import("../modules/dashboard - AOD_ContentPartner/Page"));
  const MonetizationPage = lazy(() => import("../modules/monetization/index"));
  const AnalyticsPage = lazy(() => import("../modules/analytics/index"));
  const FeedbackPage =lazy(() => import("../modules/feedbacks/index"))
  const StoragePage =lazy(() => import("../modules/storage-settings/index"))
  const CustomerListPage =lazy(() => import("../modules/customerslist/index"))
  const IntegrationPage = lazy(() => import("../modules/settings/AccountPage"));
  const SettingsPage = lazy(() => import("../modules/settings/index"));
  const PageSettingsPage = lazy(() => import("../modules/page-settings/index"));
  const FirebasePage = lazy(() => import("../modules/settings/AccountPage"));
  const SettingContentPage = lazy(() => import("../modules/content-settings/index"));
  const AudioPage = lazy(() => import("../modules/audiocontent/index"));

  const CUSTOMER_API_URL = process.env.REACT_APP_API_URL + "/customers/info";
  const [customerType, setCustomerType] = useState<any>("");


  useEffect(() => {
    axios.get(`${CUSTOMER_API_URL}`)
    .then((d) => {
      const data = d.data.data;
      
      if(data && data.customerType){
        sessionStorage.setItem('CUSTOMER_CONTENT_TYPE', data.customerType);
        setCustomerType(data.customerType)
      }
    })
  }, []);

  // const getDashboard = () => {
  //   if ((currentUser?.userType !== 'CONTENT_PATNER') && (customerType === "VOD")){
  //     return <DashboardPage/>;
  //   }
  //   else if ((currentUser?.userType === 'CONTENT_PATNER')){
  //     return <DashboardContPartnerPage/>;
  //   }
  //   else if (customerType === "AOD"){
  //     return <DashboardAODPage/>;
  //   }
  // }
  const getDashboard = () => {
    if ((currentUser?.userType !== 'CONTENT_PATNER') && (customerType === "VOD")){
      return <DashboardPage/>;
    }
    else if ((currentUser?.userType === 'CONTENT_PATNER') && (customerType === "VOD")){
      return <DashboardContPartnerVODPage/>;
    }
    else if ((currentUser?.userType !== 'CONTENT_PATNER') && (customerType === "AOD")){
      return <DashboardAODPage/>;
    }
    else if ((currentUser?.userType === 'CONTENT_PATNER') && (customerType === "AOD")){
      return <DashboardContPartnerAODPage/>;
    }    
  }  



  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*"  element={
            <Navigate to={currentUser?.userType === 'SUPERADMIN' ? '/customers/list' : `${defaultPath}`} />
            // <Navigate to={currentUser?.userType === 'SUPERADMIN' ? '/customers/list' : `${getDefaultRoute()}`} />
          }/>
        <Route
          path="/dashboard"
          element={
            <SuspensedView>
             {/* {currentUser?.userType === 'CONTENT_PATNER' ? <DashboardContPartnerPage/> : <DashboardPage/>}  */}
             {getDashboard()}
            </SuspensedView>
          }
        />
        <Route
          path="/content/*"
          element={
            <SuspensedView>
              <ContentSettingsPage />
            </SuspensedView>
          }
        />
         <Route
          path="/setting-contents/*"
          element={
            <SuspensedView>
              <SettingContentPage />
            </SuspensedView>
          }
        />
        <Route
          path="monetization/*"
          element={
            <SuspensedView>
              <MonetizationPage/>
            </SuspensedView>
          }
        />
        <Route
          path="analytics/*"
          element={
            <SuspensedView>
              <AnalyticsPage/>
            </SuspensedView>
          }
        />
        <Route
          path="/content/layout/*"
          element={
            <SuspensedView>
              <LayoutPage />
            </SuspensedView>
          }
        />
         <Route
          path="/audio/layout/*"
          element={
            <SuspensedView>
              <AudioLayoutPage />
            </SuspensedView>
          }
        />
         <Route
          path="/manage/accounts/*"
          element={
            <SuspensedView>
              <ManageAccountsPage/>
            </SuspensedView>
          }
        />
        <Route
        path="/audio/*"
        element={
          <SuspensedView>
            <AudioPage/>
          </SuspensedView>
        }
      />
        <Route
          path="/manage/app-settings/*"
          element={
            <SuspensedView>
              <PageSettingsPage/>
            </SuspensedView>
          }
        />
         <Route
          path="/manage/integration/*"
          element={
            <SuspensedView>
              <SettingsPage/>
            </SuspensedView>
          }
        />
          <Route
          path="/manage/notification/*"
          element={
            <SuspensedView>
              <NotificationPage/>
            </SuspensedView>
          }
        />
        <Route
          path="/manage/accounts/feedbacks/*"
          element={
            <SuspensedView>
              <FeedbackPage/>
            </SuspensedView>
          }
        />
        <Route
          path="/manage/integration/storage/*"
          element={
            <SuspensedView>
              <StoragePage/>
            </SuspensedView>
          }
        />
         {/* <Route
          path="/integrate/firebase"
          element={
            <SuspensedView>
              <FirebasePage/>
            </SuspensedView>
          }
        /> */}
         {/* <Route
          path="/integrate/smtp"
          element={
            <SuspensedView>
              <FirebasePage/>
            </SuspensedView>
          }
        /> */}
         <Route
          path="customers/*"
          element={
            <SuspensedView>
              <CustomerListPage/>
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
