import axios, { AxiosResponse } from "axios";
import { AuthModel, UserModel } from "./_models";
import { addResponse, Response } from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL;


export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/password/forgot`;
export const RESET_PASSWORD_URL = `${API_URL}/password/change`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    username,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ data:{otpRequest:''} }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(email: string, password:string, otp:string, otpRequest:number) {
  return axios.post<{ data:{otpRequest:''} }>(RESET_PASSWORD_URL, {
    email,
    password,
    otp,
    otpRequest
  });
}

// const editLanguage = (
//   genre: GenreAdd | {},
//   genreId: number
// ): Promise<Genre | undefined | any> => {
//   return axios
//     .put(`${GENRES_URL}/${genreId}`, genre)
//     .then((response: addResponse) => response.data);
// };

// export function changePassword(currentPassword: string, newPassword:string) {
//   return axios.put<{ data:{otpRequest:''} }>(RESET_PASSWORD_URL, {
//     currentPassword,
//     newPassword
//   })
//   .then((response: addResponse) => response.data);
// }

interface ResetPasswordResponse {
  data: {
    otpRequest: string;
  };
}

export function changePassword(currentPassword: string, newPassword: string) {
  return axios.put<ResetPasswordResponse>(RESET_PASSWORD_URL, {
    currentPassword,
    newPassword
  })
  // .then((response: AxiosResponse<addResponse>) => {
  //   // Handle the response correctly
  //   return response.data;
  // });
}


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/profile`;
export const GET_USER_ACCESS_URL = `${API_URL}/permission/user`;

export function getUserByToken(loginRes: any) {
  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    params: {},
    headers: {
      Authorization: loginRes.token
    },
  });
}

export function getUserPermission(token: string) {
  return axios.get<any>(GET_USER_ACCESS_URL);
}


export const otpGeneration = async ({ request }) => {
  return await axios
    .post(API_URL + "/login/signin/otp", {request},{
      headers: {
        skipLoading: true,
      },
    })
    .then((response) => response.data);
};

export const otpValidate = async (data) => {
  return await axios
    .post(API_URL + "/login/signin/otp/validate", data)
    .then((response) => response.data);
};

export const otpResend = async ({ request }) => {
  return await axios
    .post(API_URL + "/login/signin/otp/resend", {request})
    .then((response) => response.data);
};




// export function getUserPermission(token: any) {
//   return axios.get<any>(GET_USER_ACCESS_URL, {
//     params: {},
//     headers: {
//       Authorization: token,
//       loggedInUserId: 1,
//     },
//   });
// }


