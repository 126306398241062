/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  useIllustrationsPath,
} from "../../../helpers";
import { usePermissionValues } from "../../../../app/commonComponents/useCheckPermission";

const HeaderNotificationsMenu: FC = () => {
  
  const {permissionVideos, permissionSeries, permissionLiveTV,
        permissionAlbums, permissionPodcasts, permissionPlayist, permissionAudioLayout,
        permissionPlans, permissionUserManagement,
        permissionTransaction, permissionVideoLayout, permissionStorageSettings, permissionFeedbacks} = usePermissionValues();
  
  const customerType = sessionStorage.getItem("CUSTOMER_CONTENT_TYPE");
  
  return (
  <div
    className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-350px"
    data-kt-menu="true"
    style={{
      zIndex: "107",
      position: "fixed",
      inset: "0px 0px auto auto",
      margin: "0px",
      transform: "translate3d(-126px, -158.5px, 0px)",
    }}
    data-popper-placement="bottom-end"
  >
    <div className="card">
      {/* <!--begin::Card header--> */}
      <div className="card-header">

        <div className="card-title">Quick Links</div>
        
      </div>
      {/* <!--end::Card header--> */}

      {/* <!--begin::Card body--> */}
      <div className="card-body py-5">
        {/* <!--begin::Scroll--> */}
        <div className="mh-450px scroll-y me-n5 pe-5">
          {/* <!--begin::Row--> */}
          <div className="row g-2">
            {/* <!--begin::Col--> */}
            {customerType === "VOD" && (
            <>
            <div className="col-4">
              <a
                href="/content/video/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionVideos.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
                >
                <i className="ki-duotone ki-youtube fs-2hx text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Videos</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/content/playlist/series"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionSeries.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >
                <i className="ki-duotone ki-abstract-26 fs-2hx text-warning">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Series</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/content/livestream/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionLiveTV.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                <i className="ki-duotone ki-satellite fs-2hx text-dark">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Live TV</span>
              </a>
            </div>
            </>
            )}

            {customerType === "AOD" && (
            <>
            <div className="col-4">
              <a
                href="/audio/albums/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionAlbums.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
                >
                <i className="ki-duotone ki-abstract-26 fs-2hx text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Albums</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/audio/podcast/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionPodcasts.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >
                <i className="ki-duotone ki-speaker fs-2hx text-warning">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="fw-semibold">Podcast</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/audio/playlist/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionPlayist.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              > <i className="ki-duotone ki-abstract-41 fs-2hx text-dark">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Playlist</span>
              </a>
            </div>
            </>
            )}


            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/monetization/plans"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionPlans.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                <i className="ki-duotone ki-shop fs-2hx text-info">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="fw-semibold">Plans</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/manage/accounts/users"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionUserManagement.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                <i className="ki-duotone ki-people fs-2hx text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="fw-semibold">Customers</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/monetization/transaction"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionTransaction.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                <i className="ki-duotone ki-dollar fs-2hx text-success">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
                <span className="fw-semibold">Transactions</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            {(customerType === "VOD") && (
            <div className="col-4">
              <a
                href="/content/layout/general-layout"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionVideoLayout.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >  <i className="ki-duotone ki-menu fs-2hx text-body">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                </i>
                <span className="fw-semibold">Layouts</span>
              </a>
            </div>
            )}
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             {(customerType === "AOD") && (
             <div className="col-4">
              <a
                href="/audio/layout/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionAudioLayout.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >   <i className="ki-duotone ki-menu fs-2hx text-body">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                </i>
                <span className="fw-semibold">Layouts</span>
              </a>
            </div>
            )}
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             <div className="col-4">
              <a
                href="/manage/integration/storage/list"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionStorageSettings.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                 <i className="ki-duotone ki-folder fs-2hx text-info">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
                <span className="fw-semibold">Storage</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
            {/* <!--begin::Col--> */}
            <div className="col-4">
              <a
                href="/manage/accounts/feedbacks/list"
                className="d-flex flex-column flex-center text-center text-gray-800 bg-hover-light rounded py-4 px-3 mb-3"
                style={
                  !permissionFeedbacks.isViewable
                    ? { pointerEvents: "none", opacity: 0.5, userSelect: "none" }
                    : undefined
                }
              >                <i className="ki-duotone ki-book-square fs-2hx text-warning">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="fw-semibold">Feedbacks</span>
              </a>
            </div>
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             {/* <div className="col-4">
              <a
                href="/audio/albums/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                 <i className="ki-duotone ki-folder fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Albums</span>
              </a>
            </div> */}
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             {/* <div className="col-4">
              <a
                href="/audio/podcast/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                 <i className="ki-duotone ki-abstract-45 fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Podcasts</span>
              </a>
            </div> */}
            {/* <!--end::Col--> */}
             {/* <!--begin::Col--> */}
             {/* <div className="col-4">
              <a
                href="/audio/layout/entries"
                className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
              >
                 <i className="ki-duotone ki-abstract-43 fs-2hx text-active-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="fw-semibold">Audio Layouts</span>
              </a>
            </div> */}
            {/* <!--end::Col--> */}
          </div>
          {/* <!--end::Row--> */}
        </div>
        {/* <!--end::Scroll--> */}
      </div>
      {/* <!--end::Card body--> */}
    </div>
    {/* <!--end::Card--> */}
  </div>
)
}

export { HeaderNotificationsMenu };
