/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { useLayout } from "../../../core";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import { useAuth } from "../../../../../app/modules/auth";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  menuTrigger?: 'click' | `{default:'click', lg: 'hover'}`
  menuPlacement?: 'right-start' | 'bottom-start' | 'left-start' | 'bottom-end'
  hasArrow?: boolean
  hasBullet?: boolean
  isMega?: boolean
}

const MegaManage: FC<Props & WithChildren>  = (
  {children,
    to,
    title,
    icon,
    fontIcon,
    menuTrigger,
    menuPlacement,
    hasArrow = false,
    hasBullet = false,
    isMega = false,
  }
) => {
  const { setLayoutType, setToolbarType } = useLayout();
  const {pathname} = useLocation()
  const menuItemRef = useRef<HTMLDivElement>(null);

  const { currentUser } = useAuth();

  const {
    permissionContentPartner, permissionAdminUsers, permissionRoles, 
	permissionBroadcastGroups, permissionBroadcastMessages, 
	permissionFeedbacks, permissionStorageSettings, permissionGeoFencing, 
    permissionUserManagement, permissionSocialLinks, permissionImageConfig
    } = usePermissionValues();

    const permission_Accounts =  permissionUserManagement.isViewable || permissionAdminUsers.isViewable || 
	                             permissionContentPartner.isViewable || permissionRoles.isViewable;

	const permission_Broadcast = permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable;

	const permission_App_Settings = permissionSocialLinks.isViewable || permissionImageConfig.isViewable || permissionGeoFencing.isViewable;



  useEffect(() => {
    if (menuItemRef.current && menuTrigger && menuPlacement) {
      menuItemRef.current.setAttribute('data-kt-menu-trigger', menuTrigger)
      menuItemRef.current.setAttribute('data-kt-menu-placement', menuPlacement)
    }
  }, [menuTrigger, menuPlacement])

  return (
  <div
    className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible user-select-none"
    data-kt-menu-dismiss="true"
  >
    <div className="py-4 py-lg-8 px-lg-4">
	{/* <div className="tab-pane active w-lg-1000px" id="kt_app_header_menu_pages_pages"></div> */}
		<div className="row">
			<div className="col-lg-12">
				<div className="row">
				{((permission_Accounts) || (permissionFeedbacks.isViewable)) && (
					<div className="col-lg-4 mb-6 mb-lg-0">
						{(permission_Accounts) && (
					    <div className="mb-6">
							<h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">Accounts</h4>
								{(permissionUserManagement.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/accounts/users"
									className={clsx('menu-link bg-hover-secondary', {
									active: checkIsActive(pathname, '/manage/accounts/users'),
									})}
								>										
			                		<span className="menu-title">Customers</span>
								</Link>
								</div>
								)}

								{(permissionAdminUsers.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/accounts/admins"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/accounts/admins'),
									})}
								>										
			                		<span className="menu-title">Admins</span>
								</Link>
								</div>
								)}

								{(permissionContentPartner.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/accounts/cont-partner"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/accounts/cont-partner'),
									})}
								>										
			                		<span className="menu-title">Content Partner</span>
								</Link>
								</div>
								)}

								{(permissionRoles.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/accounts/roles"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/accounts/roles'),
									})}
								>										
			                		<span className="menu-title">Roles</span>
								</Link>
								</div>	
								)}															
						</div>
						)}

					    {(permissionFeedbacks.isViewable) && (
					    <div className="mb-0">
							<h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">Help & Support</h4>
							<div className="menu-item p-0 m-0">
								<Link
									to="/manage/accounts/feedbacks"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/accounts/feedbacks'),
									})}
								>										
			                		<span className="menu-title">Feedbacks</span>
								</Link>
								</div>	 
						</div>    
						)}                              																		
																		
					</div>
					)}

					{((permission_App_Settings) || (permission_Broadcast)) && (
					<div className="col-lg-4 mb-6 mb-lg-0">
						{(permission_App_Settings) && (
					    <div className="mb-6">
							<h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">App Settings</h4>
							{(permissionSocialLinks.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/app-settings/social-links"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/app-settings/social-links'),
									})}
								>										
			                		<span className="menu-title">Social Links</span>
								</Link>
								</div>
							)}
								{(permissionImageConfig.isViewable) && (						
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/app-settings/image-config/login"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/app-settings/image-config/login'),
									})}
								>										
			                		<span className="menu-title">Assets</span>
								</Link>
								</div>
								)}

								{(permissionGeoFencing.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/app-settings/geo-fencing"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/app-settings/geo-fencing'),
									})}
								>										
			                		<span className="menu-title">Geo Fencing</span>
								</Link>
								</div>
								)}
						</div>
						)}

					    {(permission_Broadcast) && (
					    <div className="mb-0">
							<h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">Notification</h4>
							{(permissionBroadcastMessages.isViewable) && (
							<div className="menu-item p-0 m-0">
								<Link
									to="/manage/notification/broadcast"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/notification/broadcast'),
									})}
								>										
			                		<span className="menu-title">Broadcast</span>
								</Link>
							</div>	 
							)}
							{(permissionBroadcastGroups.isViewable) && (
							<div className="menu-item p-0 m-0">
								<Link
									to="/manage/notification/groups"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/notification/groups'),
									})}
								>										
			                		<span className="menu-title">Groups</span>
								</Link>
							</div>	
							)} 							
						</div>  
						)}                                																		
																		
					</div>		
					)}			
																	
					{((currentUser?.userType === "SUPERADMIN") || (permissionStorageSettings.isViewable)) && (
					<div className="col-lg-4 mb-6 mb-lg-0">																		
						<div className="mb-6">
							<h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">Integrations</h4>
							{(currentUser?.userType === "SUPERADMIN") && (
							<>						   
							<div className="menu-item p-0 m-0">
								<Link
									to="/manage/integration/email-config"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/integration/email-config'),
									})}
								>										
			                		<span className="menu-title">Email Settings</span>
								</Link>
								</div>
																		
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/integration/tmdb"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/integration/tmdb'),
									})}
								>										
			                		<span className="menu-title">TMDB</span>
								</Link>
								</div>

							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/integration/firebase"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/integration/firebase'),
									})}
								>										
			                		<span className="menu-title">Firebase</span>
								</Link>
								</div>
								</>
								)}
							    {(permissionStorageSettings.isViewable) && (
							    <div className="menu-item p-0 m-0">
								<Link
									to="/manage/integration/storage"
									className={clsx('menu-link bg-hover-secondary ', {
									active: checkIsActive(pathname, '/manage/integration/storage'),
									})}
								>										
			                		<span className="menu-title">Storage</span>
								</Link>
								</div>	
								)}															
						</div>
             		</div>
					)}
				</div>                                  
			</div>
		</div>
    </div>
    </div>
  );
};

export { MegaManage };
