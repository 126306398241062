import { useState, useEffect } from "react";
import axios from "axios";

let requestCount = 0;
let timeoutId: ReturnType<typeof setTimeout> | null = null;

export function useGlobalLoader() {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const startLoader = () => {
      requestCount++;
      setLoader(true);

      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          setLoader(false);
          requestCount = 0;
        }, 5000);
      }
    };

    const stopLoader = () => {
      requestCount--;
      if (requestCount <= 0) {
        setLoader(false);
        requestCount = 0; // Reset to prevent negative values
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      }
    };

    const requestInterceptor = axios.interceptors.request.use((config) => {
      if (!config.headers?.skipLoading) {
        startLoader();
      }
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (!response.config.headers?.skipLoading) {
          stopLoader();
        }
        return response;
      },
      (error) => {
        if (!error.config.headers?.skipLoading) {
          stopLoader();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return loader;
}
