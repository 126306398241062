/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import './AuthLayout.scss'
const AuthLayoutNew = () => {
//   useEffect(() => {
//     const root = document.getElementById("root");
//     document.body.style.setProperty(
//       "background-image",
//       `url(${toAbsoluteUrl("/media/auth/bgNew.jpeg")})`
//     );
//     if (root) {
//       root.style.height = "100%";
//     }
//     return () => {
//       document.body.style.setProperty("background-image", "none");

//       if (root) {
//         root.style.height = "auto";
//       }
//     };
//   }, []);
  return (
	<div className="d-flex flex-column flex-root" id="kt_app_root">
	<div className="d-flex flex-column flex-lg-row flex-column-fluid">
		<div className="d-flex flex-lg-row-fluid">
			<div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
				<img className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src={toAbsoluteUrl("/media/auth/agency.png")} alt="" />
				{/* <img className="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/media/auth/agency-dark.png" alt="" /> */}
				<h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">Fast, Efficient and Productive</h1>
				<div className="text-gray-600 fs-base text-center fw-semibold">In this kind of post, 
				<a href="#" className="opacity-75-hover text-primary me-1">the blogger</a>introduces a person they’ve interviewed 
				<br />and provides some background information about 
				<a href="#" className="opacity-75-hover text-primary me-1"> the interviewee</a>and their 
				<br />work following this is a transcript of the interview.</div>
			</div>
		</div>
		<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
		{/* <div className="d-flex bg-body  rounded-4 w-md-600px flex-center flex-lg-start flex-column p-20"> */}

					<Outlet/>
		{/* </div> */}
		</div>
	</div>
	<style>
		{
		`body { background-image: url(${toAbsoluteUrl("/media/auth/bg10.jpeg")});`
		} 
	</style>
</div>
  );
};

export { AuthLayoutNew };



{/* <div className="d-flex flex-stack px-lg-10">
							<div className="me-0"> */}
								{/* <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
									<img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src={toAbsoluteUrl("/media/flags/united-states.svg")} alt="" />
									<span data-kt-element="current-lang-name" className="me-1">English</span>
									<i className="ki-duotone ki-down fs-5 text-muted rotate-180 m-0"></i>
								</button> */}
								{/* <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
									<div className="menu-item px-3">
										<a href="#" className="menu-link d-flex px-5" data-kt-lang="English">
											<span className="symbol symbol-20px me-4">
												<img data-kt-element="lang-flag" className="rounded-1" src={toAbsoluteUrl("/media/flags/united-states.svg")} alt="" />
											</span>
											<span data-kt-element="lang-name">English</span>
										</a>
									</div>
									
								</div> */}
							{/* </div> */}
							{/* <div className="d-flex fw-semibold text-primary fs-base gap-5">
								<a href="/" >Terms</a>
								<a href="/" >Plans</a>
								<a href="/" >Contact Us</a>
							</div> */}
						{/* </div> */}