/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { useLayout } from "../../../core";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  menuTrigger?: 'click' | `{default:'click', lg: 'hover'}`
  menuPlacement?: 'right-start' | 'bottom-start' | 'left-start'
  hasArrow?: boolean
  hasBullet?: boolean
  isMega?: boolean
}

const MegaDashAnalytics: FC<Props & WithChildren>  = (
  {children,
    to,
    title,
    icon,
    fontIcon,
    menuTrigger,
    menuPlacement,
    hasArrow = false,
    hasBullet = false,
    isMega = false,
  }
) => {
  const { setLayoutType, setToolbarType } = useLayout();
  const {pathname} = useLocation()
  const menuItemRef = useRef<HTMLDivElement>(null)
  const { permissionVideos, permissionSeries, permissionLiveTV, permissionEPG,
          permissionLibrary, permissionEncoding, permissionVideoLayout, 
          permissionCustomerAnalystics, permissionGeoFencing,
          permissionDeviceAnalystics, permissionGeoLocationAnalystics, permissionRealTimeAnalystics,
          permissionRevenueAnalystics, permissionContentAnalystics,
          permissionAudioAnalystics,permissionAudioAlbumAnalystics, permissionAudioTrackAnalystics, permissionAudioPlaylistAnalystics,
          permissionContentPartnerAnalystics, permissionMonitorAnalystics, } = usePermissionValues();
  
const customerType = sessionStorage.getItem("CUSTOMER_CONTENT_TYPE");
  

  const Permission_Analytics =  permissionCustomerAnalystics.isViewable || permissionContentAnalystics.isViewable ||
                                permissionDeviceAnalystics.isViewable || permissionGeoLocationAnalystics.isViewable || 
                                permissionRealTimeAnalystics.isViewable || permissionRevenueAnalystics.isViewable;

  useEffect(() => {
    if (menuItemRef.current && menuTrigger && menuPlacement) {
      menuItemRef.current.setAttribute('data-kt-menu-trigger', menuTrigger)
      menuItemRef.current.setAttribute('data-kt-menu-placement', menuPlacement)
    }
  }, [menuTrigger, menuPlacement])

  return (
  <div
    className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible user-select-none"
    data-kt-menu-dismiss="true"
  >
    <div className="row">
      <div className={`col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6`}>
      {/* <div className={`${Permission_Analytics ? `col-lg-8`: `col-lg-12`} mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6`}> */}
        <div className="row">
          {((permissionContentAnalystics.isViewable) && (customerType === "VOD")) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-content"
                className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-content'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                <i className="bi bi-bar-chart-fill fs-1 text-blue-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                 
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Content</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Content Insights
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{((permissionAudioAlbumAnalystics.isViewable) && (customerType === "AOD")) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-audio-album"
                className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-audio-album'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                <i className="bi bi-music-note-list fs-1 text-blue-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                 
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Album</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Album Insights
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{((permissionAudioTrackAnalystics.isViewable) && (customerType === "AOD")) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-audio-track"
                className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-audio-track'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                <i className="bi bi-file-music-fill fs-1 text-blue-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                 
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Track</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Track Analysis
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{((permissionAudioPlaylistAnalystics.isViewable) && (customerType === "AOD")) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-audio-playlist"
                className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-audio-playlist'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                <i className="bi bi-file-earmark-play-fill fs-1 text-blue-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                 
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Playlist</span>
                  <span className="fs-7 fw-semibold text-muted">
                  User Preferences
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionCustomerAnalystics.isViewable) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/audience"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/audience'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-person-lines-fill text-danger fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Customer</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Analyze Users
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionContentPartnerAnalystics.isViewable) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/partner"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/partner'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-fast-forward-btn-fill text-info fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Content Partner</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Partner Reports
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{((permissionDeviceAnalystics.isViewable) && (customerType === "VOD")) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-devices"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-devices'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-pc-display text-primary fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Devices</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Optimize Devices
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
          {(permissionGeoLocationAnalystics.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-geolocation"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-geolocation'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-pin-map-fill text-warning fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                    <span className="path15"></span>
                    <span className="path16"></span>
                    <span className="path17"></span>
                    <span className="path18"></span>
                    <span className="path19"></span>
                    <span className="path20"></span>
                    <span className="path21"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">
                  Geo Location
                  </span>
                  <span className="fs-7 fw-semibold text-muted">
                  Global Insights
                  </span>
                </span>
              </Link>
            </div>
          </div>
  }
  {(permissionRealTimeAnalystics.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-realtime"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-realtime'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-stopwatch-fill text-danger fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Real Time</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Instant Metrics
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionRevenueAnalystics.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-revenue"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-revenue'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-cash stack text-success fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Revenue</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Measure Growth
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionMonitorAnalystics.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-monitor"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/analytics/analytics-monitor'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="bi bi-pie-chart-fill text-blue-primary fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Grafana Dashboard</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Visualize Data
                  </span>
                </span>
              </Link>
            </div>
          </div>
}       
        </div>

      {/* {(permissionGeoFencing.isViewable) && (
        <>
        <div className="separator separator-dashed mx-5 my-5"></div>
        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
          <div className="d-flex flex-column me-5">
            <div className="fs-6 fw-bold text-gray-800">
            App Settings
            </div>
            <div className="fs-7 fw-semibold text-muted">
            Manage Geolocation Settings with Ease
            </div>
          </div>
          <Link
            to="/manage/geofencing"
            className="btn btn-sm btn-primary fw-bold"
            style={{backgroundColor:'#3699ff', color:'#fff'}}
          >
            Explore
          </Link>
        </div>
        </>)} */}

      </div>

    </div>
    </div>
  );
};

export { MegaDashAnalytics };
