import { CSSProperties } from "react";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
const LoadingSpinner = () => {
  const styles:CSSProperties = {
    position: "fixed", 
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)", 
    display: "flex",
    justifyContent: "center", 
    alignItems: "center",
    zIndex: "9999",
  };

  const contentStyles:CSSProperties = {
    // borderRadius: "0.475rem",
    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    // backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    width: "auto",
    padding: "1rem 2rem",
    textAlign: "center",
  };

  return (
    <div style={styles}>
      <div style={contentStyles}>
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/logos/spin.gif")}
          className="h-50px h-lg-60px app-sidebar-logo-default"
        />
        <div>Loading...</div>
      </div>
    </div>
  );
};

export { LoadingSpinner };
