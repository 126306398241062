import { AuthModel, UserModel } from "./_models";
const AUTH_LOCAL_STORAGE_KEY = "auth-key";

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getPermissions = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("permission");
  if (!lsValue) {
    return;
  }

  try {
    const permissions = JSON.parse(lsValue);
    if (permissions) {
      // You can easily check auth_token expiration also
      return permissions;
    }
  } catch (error) {
    console.error("permissions LOCAL STORAGE PARSE ERROR", error);
  }
};
const setPermissions = (permissions) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(permissions);
    localStorage.setItem("permission", lsValue);
  } catch (error) {
    console.error("permission LOCAL STORAGE SAVE ERROR", error);
  }
};

const removePermissions = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("permission");
  } catch (error) {
    console.error("permission LOCAL STORAGE REMOVE ERROR", error);
  }
};
const getUser = (): UserModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("userDetails");
  if (!lsValue) {
    return;
  }

  try {
    const permissions = JSON.parse(lsValue);
    if (permissions) {
      // You can easily check auth_token expiration also
      return permissions;
    }
  } catch (error) {
    console.error("userDetails LOCAL STORAGE PARSE ERROR", error);
  }
};
const setUser = (permissions) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(permissions);
    localStorage.setItem("userDetails", lsValue);
  } catch (error) {
    console.error("userDetails LOCAL STORAGE SAVE ERROR", error);
  }
};
const removeUser = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("userDetails");
  } catch (error) {
    console.error("userDetails LOCAL STORAGE REMOVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `${auth.token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
    axios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        if (error.response && error.response.status === 401) {
          console.log("Token expired or invalid. Redirecting to login...");
          window.location.href = "/auth";
        }
        return Promise.reject(error);
      }
    );
}

export {
  getAuth,
  setAuth,
  removeAuth,
  getPermissions,
  setPermissions,
  removePermissions,
  setUser,
  getUser,
  removeUser,
  AUTH_LOCAL_STORAGE_KEY,
};
