import { useState, useEffect } from 'react';
import { usePermissionValues } from './useCheckPermission';

export function useGetFirstPath() {

  const customerType = sessionStorage.getItem("CUSTOMER_CONTENT_TYPE");

  const { 
    permissionVideos, permissionSeries, permissionLiveTV, permissionEPG, permissionLibrary, permissionEncoding, 
    permissionVideoLayout, permissionAlbums, permissionPodcasts, permissionPlayist, 
    permissionAudioGenres, permissionAudioLayout, permissionCategories, permissionLanguage, 
    permissionGenres, permissionProdHouse, permissionCasts, permissionGeoRestriction, 
    permissionContentPartner, permissionAdminUsers, permissionRoles, permissionBroadcastGroups, 
    permissionBroadcastMessages, permissionContentAnalystics, permissionCustomerAnalystics, 
    permissionAudioAnalystics, permissionAudioAlbumAnalystics, permissionAudioTrackAnalystics, permissionAudioPlaylistAnalystics,
    permissionDeviceAnalystics, permissionGeoLocationAnalystics, 
    permissionRealTimeAnalystics, permissionRevenueAnalystics, permissionContentPartnerAnalystics, permissionMonitorAnalystics,
    permissionFeedbacks, permissionPlans, permissionTransaction, permissionSubscription, permissionRental, permissionAds,
    permissionDashboard, permissionSocialLinks, permissionImageConfig,
    permissionGeoFencing, permissionUserManagement, permissionPaymentGateway, permissionStorageSettings, permissionCoupon
  } = usePermissionValues();

  const permission_Content_Settings = (
    permissionCategories.isViewable || permissionLanguage.isViewable || permissionGenres.isViewable ||
    permissionProdHouse.isViewable || permissionCasts.isViewable || permissionGeoRestriction.isViewable );

  const Permission_Analytics =  permissionContentAnalystics.isViewable || permissionCustomerAnalystics.isViewable || 
  permissionAudioAnalystics.isViewable || permissionAudioAlbumAnalystics.isViewable ||
  permissionAudioTrackAnalystics.isViewable || permissionAudioPlaylistAnalystics.isViewable ||
  permissionDeviceAnalystics.isViewable || 
  permissionGeoLocationAnalystics.isViewable || permissionRealTimeAnalystics.isViewable || 
  permissionRevenueAnalystics.isViewable || permissionContentPartnerAnalystics.isViewable || permissionMonitorAnalystics.isViewable

  const permission_Admin = (
    permissionAdminUsers.isViewable || permissionRoles.isViewable
  );

  const permission_Broadcast = (
    permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable
  );

  const permission_App_Settings = (
    permissionSocialLinks.isViewable || permissionImageConfig.isViewable || permissionGeoFencing.isViewable
  );

  const permission_Accounts =  permissionUserManagement.isViewable || permissionAdminUsers.isViewable || 
  permissionContentPartner.isViewable || permissionRoles.isViewable;

  const [defaultPath, setDefaultPath] = useState(null);

  useEffect(() => {
    const getDefaultRoute = () => {
      if (permissionDashboard.isViewable) {
        return "/dashboard";
      }
      if ((
        permissionVideos.isViewable || permissionSeries.isViewable || permissionLiveTV.isViewable || permissionEPG.isViewable ||
        permissionLibrary.isViewable || permissionEncoding.isViewable || permissionVideoLayout.isViewable
      ) && (customerType === "VOD")) {
        return "/content";
      }
      if ((
        permissionAlbums.isViewable || permissionPodcasts.isViewable || permissionPlayist.isViewable ||
        permissionAudioGenres.isViewable || permissionAudioLayout.isViewable
        ) && (customerType === "AOD")) {
          return "/audio";
      }
      if (permission_Content_Settings) {
        return "/setting-contents";
      }
      if (Permission_Analytics) {
        return "/analytics";
      }
      if (permissionPlans.isViewable || permissionTransaction.isViewable || 
         permissionSubscription.isViewable || permissionRental.isViewable ||
         permissionPaymentGateway.isViewable || permissionCoupon.isViewable || permissionAds.isViewable) {
        return "/monetization";
      }
      if (permission_Accounts) {
        return "/manage/accounts";
      }
      if (permission_Broadcast) {
        return "/manage/notification";
      }
      if (permission_App_Settings) {
        return "/app-settings";
      }
      if (permissionStorageSettings.isViewable) {
        return "/manage/integration/storage";
      }
      // if (permissionUserManagement.isViewable) {
      //   return "/manage/accounts/users";
      // }
      // if (permissionFeedbacks.isViewable) {
      //   return "/manage/accounts/feedbacks";
      // }
      // if (permissionGeoFencing.isViewable) {
      //   return "/manage/geofencing";
      // }

      // return "/dashboard";
    };

    if (permissionVideos.isViewable !== undefined) {
      const route = getDefaultRoute();
      setDefaultPath(route);
    }
  }, [
    permissionDashboard, permissionVideos, permissionSeries, permissionLiveTV, permissionLibrary, permissionEPG, 
    permissionEncoding, permissionVideoLayout, permissionAlbums, permissionPodcasts, permissionPlayist, 
    permissionAudioGenres, permissionAudioLayout, permissionPlans, permissionTransaction, 
    permissionCategories, permissionLanguage, permissionGenres, permissionProdHouse, permissionCasts, 
    permissionGeoRestriction, permissionContentPartner, permissionAdminUsers, permissionRoles, 
    permissionBroadcastGroups, permissionBroadcastMessages, permissionUserManagement, 
    permissionFeedbacks, permissionGeoFencing, permissionStorageSettings, permissionPaymentGateway
  ]);

  return defaultPath;
}
